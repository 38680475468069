@import url("https://fonts.googleapis.com/css2?family=Edu+QLD+Beginner:wght@500&family=Mukta:wght@300&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Mukta", sans-serif;
  background-color: #2e0249;
  color: #fff;
  text-align: center;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #a91079;
}

h1 {
  font-family: "Edu QLD Beginner", cursive;
}

img {
  width: 70%;
}

nav {
  display: flex;
  justify-content: space-evenly;
  background-color: #a91079;
  padding-top: 1%;
  padding-bottom: 1%;
}

nav a:hover {
  color: #f806cc;
}

section {
  width: 95%;
  margin: 0 auto;
}

.banner {
  background-image: url(./img/city.jpg);
  padding: 20%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 3%;
  margin-bottom: 3%;
  font-size: larger;
}
